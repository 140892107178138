@tailwind base;
@tailwind components;
@tailwind utilities;
/* PF Display */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

/* Quicksand */
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');


